// New pages are set up programatically using Gatsby File System Route API. https://www.gatsbyjs.com/docs/reference/routing/file-system-route-api/

import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import slugify from "slugify"
import SEOmeta from "../components/SEOmeta"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ProjectTemplate = ({ data }) => {
  const {
    title,
    description,
    tools: { tools },
    image,
  } = data.contentfulPortfolio
  console.log(data)
  const pathToImage = getImage(image)
  // const { tags, instructions, ingredients, tools } = content
  const options = {
    renderNode: {
      hyperlink: node => {
        // Handle normal hyperlinks to open in new Browser Tab
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {node.content[0].value}
          </a>
        )
      },
    },
  }

  return (
    <Layout>
      <SEOmeta title={title} description={description} />
      <main className="page">
        <div className="project-page">
          {/* hero */}
          <section className="project-hero">
            <span className="frame">
              <GatsbyImage
                image={pathToImage}
                alt={title}
                className="about-img"
              />
            </span>
            <article className="project-info">
              <h2>{title}</h2>
              {description &&
                documentToReactComponents(JSON.parse(description.raw), options)}
              {/* <p>{description}</p> */}
              {/* icons */}
              {/* <div className="project-icons">
                <article>
                  <BsClock />
                  <h5>prep time</h5>
                  <p>{prepTime} min.</p>
                </article>
                <article>
                  <BsClockHistory />
                  <h5>cook time</h5>
                  <p>{cookTime} min.</p>
                </article>
                <article>
                  <BsPeople />
                  <h5>serving</h5>
                  <p>{servings} </p>
                </article>
              </div> */}
              {/* tags */}
              <p className="project-tools">
                Tools :
                {tools.map((tool, index) => {
                  const slug = slugify(tool, { lower: true })

                  return (
                    <Link to={`/tools/${slug}`} key={index}>
                      {tool}
                    </Link>
                  )
                })}
              </p>
            </article>
          </section>
          {/* rest of the content */}
          <section className="project-content">
            {/* <article>
              <h4>instructions</h4>
              {instructions.map((item, index) => {
                return (
                  <div key={index} className="single-instruction">
                    <header>
                      <p>step {index + 1}</p>
                      <div></div>
                    </header>
                    <p>{item}</p>
                  </div>
                )
              })}
            </article> */}
            <article className="second-column">
              {/* <div>
                <h4>ingredients</h4>
                {ingredients.map((item, index) => {
                  return (
                    <p key={index} className="single-ingredient">
                      {item}
                    </p>
                  )
                })}
              </div> */}
              {/* <div>
                <h4>tools</h4>
                {tools.map((item, index) => {
                  return (
                    <p key={index} className="single-tool">
                      {item}
                    </p>
                  )
                })}
              </div> */}
            </article>
          </section>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query getSinglePortfolio($title: String) {
    contentfulPortfolio(title: { eq: $title }) {
      title
      description {
        raw
      }
      tools {
        tools
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
      }
    }
  }
`

export default ProjectTemplate
